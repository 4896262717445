import Stack, {
    onEntryChange,
  } from '@shared/contentstack/live-preview/live-preview';
  import { TestimonialSlider } from '@src/cms-components';
  import { testimonialSliderMapper } from '@src/cms-components/testimonial-slider/mappers/testimonial-slider.mapper';
  import { useCallback, useEffect, useState } from 'react';
  import { LPContainer } from '@shared/contentstack';
  
  const TestimonialSliderPreviewPage = (props: any) => {
    const { pageContext } = props;
  
    const [livePreviewData, setLivePreviewData] = useState<any>({});
  
    Stack.startLivePreview(true);
  
    const fetchData = useCallback(async () => {
      const response = (await Stack.getEntry({
        contentTypeUid: 'customer_testimonials',
        url:
          pageContext?.url && pageContext?.url !== '/'
            ? pageContext?.url
            : '/live-preview',
      })) as any;
      const result = response[0][0];
      setLivePreviewData(result);
    }, [pageContext]);
  
    useEffect(() => {
      onEntryChange(() => fetchData());
    }, [fetchData]);
  
    return (
      !!livePreviewData && (
        <LPContainer>
          <TestimonialSlider {...testimonialSliderMapper(livePreviewData)} />
        </LPContainer>
      )
    );
  };
  
  export default TestimonialSliderPreviewPage;
  